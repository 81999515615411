import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { TwitterTimelineEmbed } from "react-twitter-embed";
import axios from "axios"

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

const priceStyles = {
  width: "50%",
  display: "inline-block",
}

const testStyles = {
  "text-align": 'center',
  margin: "auto",
}

// markup
const S2F = () => {
  const [price, setPrice] = useState(false);

  const getPrice = async () => {
    const resp = await axios.get(`https://api.coindesk.com/v1/bpi/currentprice.json`);
    setPrice(resp.data.bpi.USD.rate);
  }

  useEffect(() => {
    getPrice();
  }, []);

  return (
    <main style={pageStyles}>
      <title>Tracking Hub</title>
      <h1 style={testStyles}>${price}</h1>
      <p style={paragraphStyles}>
        Trends 12m
        <br />
        <br />
        <iframe
            id="trends-12"
            src="https://trends.google.com:443/trends/embed/explore/TIMESERIES?req=%7B%22comparisonItem%22%3A%5B%7B%22keyword%22%3A%22bitcoin%22%2C%22geo%22%3A%22US%22%2C%22time%22%3A%22today%2012-m%22%7D%5D%2C%22category%22%3A0%2C%22property%22%3A%22%22%7D&amp;tz=-480"
            width="100%"
            height="450px"
            frameborder="0"
            scrolling="0"
          />
        <br />
        <br />
        <div style={priceStyles}>
          <TwitterTimelineEmbed sourceType="profile" screenName="100trillionUSD" options={{height: "800px", width: '100%'}} />
        </div>
        <br />
        <br />
        <Link to="/">Go home</Link>
      </p>
    </main>
  )
}

export default S2F
